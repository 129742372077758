import { Injectable }         from '@angular/core';
import { DataService }        from '@omnilib/services/data.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PersonService }      from '@omnipas/services/person.service';
import { RouterService }      from '@omnipas/services/router.service';

import { differenceInMinutes, format, sub, toDate  } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  initialized:    boolean  = false;
  resetpwtoken:   string   = '';
  csrftoken:      string   = '';

  constructor ( public  data:           DataService
              , public  environment:    EnvironmentService
              , public  list:           ListService
              , public  message:        MessageService
              , public  person:         PersonService
              , public  router:         RouterService
              ) {
  }

  async loginsso ( $token ) {
    this.router.toggleshowwait( true );

    let params = {};
    params['token'] = $token;
    params['type']  = this.environment.getEnvValue('surfConfig')['loginItem'] == 'email' ? 0 : 1;

    let result = await this.data.callapi( 'post', 'auth/sso', params );

    if ( result['result']['code'] == '0' && result['result']['resultset'] && result['result']['resultset'] != '' ) {
      await this.data.setjwt( result['result']['resultset']['token'] );

      if ( await this.person.getPersonData() ) {
        this.router.goTo('welcome');
      } else {
        this.data.jwt.clear();
        this.message.setErrorMessage( 'loading.notfound', false, true );
        this.router.goTo('error');
      }
    } else {
      this.data.jwt.clear();
      this.message.setErrorMessage ( 'login.error.surf', true, true);
      this.router.goTo('error');
    }
  }

  async login ( $username, $password ) {
    this.router.toggleshowwait( true );

    if ( $username != '' && $password != '' ) {
      let params = {};
      params['loginId'] = $username;
      params['password'] = $password;

      let result = await this.data.callapi( 'post', 'auth/authenticate', params );

      if ( result['result']['code'] == 0 ) {
        this.csrftoken = this.list.getValue( result['result']['resultset'], 'csrfToken' );
        this.router.goTo('logincheck');
      } else {
        this.message.setErrorMessage ( 'login.error.invalid', true, true);
        this.router.toggleshowwait( false );
        return;
      }
    } else {
      this.message.setErrorMessage ( 'login.error.incomplete', true, true);
      this.router.toggleshowwait( false );
      return;
    }

    this.router.toggleshowwait( false );
    return;
  }

  async logincheck ( $code ) {
    this.router.toggleshowwait( true );

    if ( $code != ''  ) {
      let params = {};
      params['token'] = $code;

      let result;

      if ( this.csrftoken == '' ) {
        result = await this.data.callapi( 'post', 'auth/2fa', params );
      } else {
        result = await this.data.callapi( 'post', 'auth/2fa/' + this.csrftoken , params );
      }

      if ( result['result']['code'] == 0 && result['result']['resultset'] && result['result']['resultset'] != '' ) {
        await this.data.setjwt( result['result']['resultset']['token'] );

        if ( await this.person.getPersonData() ) {
          this.router.goTo('welcome');
        } else {
          this.message.setErrorMessage( 'loading.notfound', false, true );
        }
      } else {
        this.message.setErrorMessage ( 'login.error.invalid', true, true);
      }
    } else {
      this.message.setErrorMessage ( 'login.error.incomplete', true, true);
      this.router.toggleshowwait( false );
      return;
    }

    this.router.toggleshowwait( false );
  }

  logout() {
    this.data.jwt.clear();
  }
}
