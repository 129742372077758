<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <form *ngIf="!message.success && !message.error" action="" method="">
    <div class="w3-margin">
      <label for="username">{{ 'labels.username' | translate: router.transparams }}</label>
      <input
        id            = "username"
        name          = "username"
        type          = "username"
        class         = "w3-input w3-border w3-input-theme"
        placeholder   = "{{ 'labels.username' | translate: router.transparams }}"
        [(ngModel)]   = "username"
        required      = "required"
        tabindex      = "2"
      >
    </div>

    <div class="w3-margin">
      <label for="email">{{ 'labels.email' | translate: router.transparams }}</label>
      <input
        id            = "email"
        name          = "email"
        type          = "email"
        class         = "w3-input w3-border w3-input-theme"
        placeholder   = "{{ 'labels.email' | translate: router.transparams }}"
        [(ngModel)]   = "email"
        required      = "required"
        tabindex      = "2"
      >
    </div>
    <div class="w3-margin">
      <input
        type        = "reset"
        id          = "reset"
        name        = "reset"
        class       = "w3-button w3-border w3-button-theme w3-margin-right pageButton w3-round"
        title       = "{{ 'buttons.reset' | translate: router.transparams }}"
        value       = "{{ 'buttons.reset' | translate: router.transparams }}"
        (click)     = "reset()"
        [disabled]  = "enableReset() ? '' : 'disabled'"
        tabindex    = "3"
      >
      <input
        type        = "submit"
        id          = "submit"
        name        = "submit"
        class       = "w3-button w3-border w3-button-theme pageButton w3-round"
        title       = "{{ 'buttons.send' | translate: router.transparams }}"
        value       = "{{ 'buttons.send' | translate: router.transparams }}"
        (click)     = "submit()"
        [disabled]  = "enableSubmit() ? '' : 'disabled'"
        tabindex    = "4"
      >
    </div>
  </form>
</div>
