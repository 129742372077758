<div *ngIf="initialized && ( environment.getEnvValue('logintype') == 'database' || environment.getEnvValue('logintype') == 'framework' )" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <form action="" method="" *ngIf="!message.error">
    <div class="w3-margin">
      <label for="username">{{ 'labels.username' | translate: router.transparams }}</label>
      <input
        id            = "username"
        name          = "username"
        class         = "w3-input w3-border w3-input-theme w3-round"
        type          = "text"
        placeholder   = "{{ 'labels.username' | translate: router.transparams }}"
        autocomplete  = "off"
        [(ngModel)]   = "username"
        required      = "required"
        tabindex      = "1"
      >
    </div>
    <div class="w3-margin">
      <label for="password">{{ 'labels.password' | translate: router.transparams }}</label>
      <input
        id            = "password"
        type          = "password"
        name          = "password"
        class         = "w3-input w3-border w3-input-theme password w3-round"
        placeholder   = "{{ 'labels.password' | translate: router.transparams }}"
        autocomplete  = "off"
        [(ngModel)]   = "password"
        required      = "required"
        (keyup.enter) = "submit()"
        tabindex      = "2"
      >
      <fa-icon
        id          = "togglePassword"
        name        = "togglePassword"
        [icon]      = "eye"
        (mouseover) = "togglePassword( true )"
        (mouseout)  = "togglePassword( false )"
        title       = "{{ 'login.showhide' | translate: router.transparams }}"
      ></fa-icon>
    </div>
    <div class="w3-margin">
      <input
        type        = "reset"
        id          = "reset"
        name        = "reset"
        class       = "w3-button w3-border w3-button-theme w3-margin-right pageButton w3-round"
        title       = "{{ 'buttons.reset' | translate: router.transparams }}"
        value       = "{{ 'buttons.reset' | translate: router.transparams }}"
        (click)     = "reset()"
        [disabled]  = "enableReset() ? '' : 'disabled'"
        tabindex    = "3"
      >
      <input
        type        = "button"
        id          = "submit"
        name        = "submit"
        class       = "w3-button w3-border w3-button-theme pageButton w3-round"
        title       = "{{ 'buttons.login' | translate: router.transparams }}"
        value       = "{{ 'buttons.login' | translate: router.transparams }}"
        (click)     = "submit()"
        [disabled]  = "enableSubmit() ? '' : 'disabled'"
        tabindex    = "4"
      >
    </div>
  </form>
  <div class="w3-margin">&nbsp;</div>
  <div *ngIf="environment.getEnvValue('logintype') == 'database' && this.environment.getEnvValue('requestnewpassword')" class="w3-margin">
    <a class="link" (click)="requestNew()" tabindex = "5">{{ 'login.forgotten' | translate: router.transparams }}</a>
  </div>
  <div *ngIf="router.transparams.customer_shortname == 'HVA' || router.transparams.customer_shortname == 'UVA'" class="w3-margin">
    <p>{{ 'login.moreinfo' | translate: router.transparams }}<br/>
      <b>{{ 'login.students' | translate: router.transparams }}:</b>&nbsp;<a target="_blank" href="{{ 'login.studenturl' | translate: router.transparams }}">{{ 'login.clickhere' | translate: router.transparams }}</a><br/>
      <b>{{ 'login.employees' | translate: router.transparams }}:</b>&nbsp;<a target="_blank" href="{{ 'login.employeeurl' | translate: router.transparams }}">{{ 'login.clickhere' | translate: router.transparams }}</a><br/>
    </p>
  </div>
  <div *ngIf="environment.getEnvValue('horizon')" class="w3-margin">
    <span>Problemen met inloggen?</span><br/>
    <span>&nbsp;&nbsp;Voor studenten: stel je vraag aan <a class="link" href="mailto:studentenhelpdesk&#64;tallandcollege.nl">studentenhelpdesk&#64;tallandcollege.nl</a></span><br/>
    <span>&nbsp;&nbsp;Voor medewerkers: stel je vraag aan <a class="link" href="mailto:servicepunt&#64;tallandcollege.nl">servicepunt&#64;tallandcollege.nl</a></span><br/>
  </div>
</div>
