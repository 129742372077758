import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { RouterService }      from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-cardstatus',
  templateUrl: './cardstatus.component.html'
})
export class CardStatusComponent implements OnInit, OnDestroy {

  initialized: boolean = false
  status:      number;
  showList:    boolean = false;

  constructor ( public environment: EnvironmentService
              , public list:        ListService
              , public loading:     LoadingService
              , public pageheader:  PageHeaderService
              , public router:      RouterService
              ) {
  }

  async ngOnInit() {

    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.status = this.list.getValue( this.router.persondata, 'cardStatus', true );
      this.pageheader.setPageHeader ( 'pagetitle.cardrequeststatus', 'pagetext.cardrequeststatus' );
    }

    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  getClass($status) {
    if ( $status < this.status ) {
      if ( $status == "4" || $status == "6" ) {
        return 'hide';
      } else {
        return 'done';
      }
    } else if ( $status == this.status ) {
      return 'current'
    } else if ( $status > this.status ) {
      if ( $status == "4" || $status == "6" ) {
        return 'hide';
      } else {
        return 'todo';
      }
    } else {
      if ( $status == "4" || $status == "6" ) {
        return 'hide';
      } else {
        return '';
      }
    }
  }

  back() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  toggleShowList() {
    this.showList = !this.showList;
  }

  ngOnDestroy() {
    return;
  }
}
