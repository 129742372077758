import { Injectable } from '@angular/core';
import { DataService }   from '@omnilib/services/data.service';
import { ListService }   from '@omnilib/services/list.service';
import { RouterService } from '@omnipas/services/router.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryAddressService {
  constructor( public data:   DataService
             , public list:   ListService
             , public router: RouterService
             ) {
  }

  found:        boolean = false;
  addresstype:  string  = '';
  addressLine1: string  = '';
  addressLine2: string  = '';


  async get() {
    if ( this.list.emptyList( this.router.freefields['personfreefields'] ) ) {
      let result = await this.data.callapi ( 'get', 'freefield/person', {} );

      if ( result['result']['code'] && result['result']['code'] == '0' && !this.list.emptyList( result['result']['resultset'] ) ) {
        this.router.freefields['personfreefields'] =  result['result']['resultset'];
        this.found = true;
      }
    } else {
      this.found = true;
    }

    console.log ( this.router.freefields );

    if ( this.found && this.list.getValue( this.router.freefields['personfreefields'], 'freeField05', true ) == 'Ja' ) {
      this.addresstype  = 'deliveryaddress';
      this.addressLine1 = this.list.getValue( this.router.freefields['personfreefields'], 'freeField07', true );
      this.addressLine2 = this.list.getValue( this.router.freefields['personfreefields'], 'freeField08', true );
    } else if( this.list.getValue( this.router.persondata, 'street', true ) != '' ) {
      this.addresstype  = 'systemaddress';
      this.addressLine1 = this.list.getValue( this.router.persondata, 'street', true ) + ' ' + this.list.getValue( this.router.persondata, 'number', true ) + ' ' + this.list.getValue( this.router.persondata, 'suffix', true );
      this.addressLine2 = this.list.getValue( this.router.persondata, 'postalCode', true ) + '  ' + this.list.getValue( this.router.persondata, 'city', true );
    } else {
      this.addresstype  = 'noaddressfound';
      this.addressLine1 = '';
      this.addressLine2 = '';
    }
  }

  async update() {
    //await this.get();

    let params = { "freeField05" : 'Ja'
                 , "freeField07" : this.addressLine1
                 , "freeField08" : this.addressLine2
                 }

    let result = await this.data.callapi ( this.found ?  'put' : 'post', 'freefield/person', params );

    if ( result['result']['code'] && result['result']['code'] == '0' ) {
      return true;
    } else {
      return false;
    }
  }

}
