<div class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div *ngIf="!message.success && !message.error" class="w3-margin">
    <div class="w3-margin-top">
      <div *ngIf="vehicledata.chargingcardnumber != ''" id="current" class="w3-margin-top">{{ 'chargingcard.current' | translate: router.transparams }}: {{ vehicledata.chargingcardnumber }}</div>
      <div *ngIf="vehicledata.chargingcardnumber == ''" id="current" class="w3-margin-top">{{ 'chargingcard.unknown' | translate: router.transparams }}</div>
    </div>
    <div class="w3-margin-top w3-margin-bottom">
      <label for="new">{{ 'chargingcard.new' | translate: router.transparams }}:</label>
      <div id="new" class="w3-margin-top">
        <input
          id            = "chargingcard"
          name          = "chargingcard"
          class         = "w3-input w3-border w3-input-theme w3-round"
          type          = "text"
          placeholder   = "{{ 'labels.chargingcard' | translate: router.transparams }}"
          autocomplete  = "chargingcard"
          [(ngModel)]   = "vehicledata.chargingcardnumber"
          required      = "required"
        >
      </div>
    </div>
    <div class="w3-margin-top w3-margin-bottom">
      <input
        type        = "button"
        class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round w3-margin-right"
        name        = "cancel"
        value       = "{{ 'buttons.back' | translate: router.transparams }}"
        title       = "{{ 'buttons.back' | translate: router.transparams }}"
        (click)     = "cancel()"
      >
      <input
        type        = "button"
        class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
        name        = "submit"
        value       = "{{ 'buttons.save' | translate: router.transparams }}"
        title       = "{{ 'buttons.save' | translate: router.transparams }}"
        (click)     = "submit()"
      >
    </div>
  </div>
  <div *ngIf="(message.success || message.error) && environment.getEnvValue('logoutbutton')" class="w3-margin">
    <p>{{ 'requestcard.logout' | translate: router.transparams }}</p>
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "logout()"
    >
  </div>
</div>
