import { Component, OnInit, ViewChild, Renderer2, ElementRef, OnDestroy, EventEmitter } from '@angular/core';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { IconsService }       from '@omnipas/services/icons.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { PhotoService }       from '@omnipas/services/photo.service';
import { RouterService }      from '@omnipas/services/router.service';

import tracking from 'tracking';
import 'tracking/build/data/face.js';
import 'tracking/build/data/eye.js';

import { ImageCroppedEvent, LoadedImage, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'omnipas-photoedit',
  templateUrl: './photoedit.component.html',
})
export class PhotoEditComponent implements OnInit, OnDestroy {
  @ViewChild(ImageCropperComponent) myPhoto: ImageCropperComponent;
  @ViewChild('myFace') public myFace: ElementRef;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  initialized: boolean = true;
  photoSelected: boolean = false;

  canvasRotation: number = 0;
  transform: ImageTransform = {};

  facefound: boolean = false;

  constructor( public environment: EnvironmentService
             , public icons:       IconsService
             , public list:        ListService
             , public loading:     LoadingService
             , public message:     MessageService
             , public pageheader:  PageHeaderService
             , public photo:       PhotoService
             , public renderer:    Renderer2
             , public router:      RouterService
             ) {}

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.router.toggleshowwait( true );
    this.initialized = false;
    this.photoSelected = false;
    this.photo.photoAgreed = false;
    this.photo.publishPermission = false;

    if ( this.list.emptyList(this.router.persondata) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader(
        'pagetitle.photoedit',
        'pagetext.photodetect'
      );
      this.message.clearMessage();
    }

    this.initialized = true;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  async cropperReady() {
    await this.facetracker();
  }

  rotateLeft() {
    this.canvasRotation--;
    this.cropperReady();
  }

  rotateRight() {
    this.canvasRotation++;
    this.cropperReady();
  }

  async facetracker() {
    let track = new tracking.ObjectTracker(['face']);

    track.setInitialScale(4);
    track.setStepSize(2);
    track.setEdgesDensity(0.1);

    try {
      await tracking.track('#myPhoto img', track);
      this.router.toggleshowwait( false );
    } catch (e) {
      this.message.setErrorMessage('photo.edit.notusable');
      this.router.toggleshowwait( false );
    }

    track.on('track', (event) => {
      let height = 0;

      if (
        event.data == undefined ||
        event.data.length == undefined ||
        event.data.length == 0
      ) {
        this.myPhoto.cropper.x2 = this.myPhoto.maxSize.width - 5;
        this.myPhoto.cropper.x1 = this.myPhoto.cropper.x2 - 120;
        this.myPhoto.cropper.y1 = 5;
        this.myPhoto.cropper.y2 = this.myPhoto.cropper.y1 + 160;

        this.pageheader.setPageHeader(
          'pagetitle.photoedit',
          'pagetext.photoselect2'
        );
      } else {
        event.data.forEach((r) => {
          if (r.height > height) {
            height = r.height;

            let newHeight = height * 2;
            let newTop = Math.max(r.y - newHeight / 2, 0);
            let newBottom = Math.min(
              newTop + newHeight,
              this.myPhoto.maxSize.height
            );

            newHeight = newBottom - newTop;

            let newWidth = (newHeight / 4) * 3;

            let newLeft = Math.max(r.x - (newWidth - r.width) / 2, 0);
            let newRight = Math.min(
              newLeft + newWidth,
              this.myPhoto.maxSize.width
            );

            if (newWidth != newRight - newLeft) {
              newWidth = newRight - newLeft;

              let newNewHeight = (newWidth / 3) * 4;

              newTop += (newHeight - newNewHeight) / 2;
              newBottom -= (newHeight - newNewHeight) / 2;
              newHeight = newNewHeight;
            }

            this.myPhoto.cropper.x1 = newLeft;
            this.myPhoto.cropper.x2 = newRight;
            this.myPhoto.cropper.y1 = newTop;
            this.myPhoto.cropper.y2 = newBottom;

            this.myPhoto.crop();
          }
        });

        this.pageheader.setPageHeader(
          'pagetitle.photoedit',
          'pagetext.photoselect1'
        );
      }
    });
  }

  retry() {
    this.photoSelected = false;
  }

  select() {
    this.pageheader.setPageHeader('pagetitle.photoedit', 'pagetext.photosave1');
    this.photoSelected = true;
  }

  save() {
    const photoMiniatureWidth = this.environment.getEnvValue('photoMiniatureWidth');
    const savephotoonserver   = this.environment.getEnvValue('savephotoonserver');

    const face = this.myFace.nativeElement;
    this.photo.photoResult = face.src;

    if ( photoMiniatureWidth != null) {
      let image = new Image();

      image.src = face.src;

      image.onload = () => {
        let width = image.width;
        let height = image.height;

        let canvas = document.createElement('canvas');
        canvas.width = photoMiniatureWidth;
        canvas.height = (photoMiniatureWidth / 3) * 4;

        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        this.photo.photoMiniature = canvas.toDataURL('image/jpeg');

        if ( savephotoonserver ) {
          this.photo.savetofile();
        } else {
          this.photo.save();
        }
      };
    } else {
      if ( savephotoonserver ) {
        this.photo.savetofile();
      } else {
        this.photo.save();
      }
    }
  }

  back() {
    this.photo.photoResult = '';
    this.router.goBack();
  }

  onResize($event) {
    this.facetracker();
  }

  loadImageFailed() {
    this.message.setErrorMessage('photo.edit.notusable');
    this.router.toggleshowwait( false );
  }

  showpopup() {
    this.router.showpermissionsinfo = true;
  }

  doNothing() {}

  ngOnDestroy() {
    return;
  }

  savebuttontext() {
    return (    (    this.router.transparams.customer_shortname == 'HVA'
                  || this.router.transparams.customer_shortname == 'UVA'
                )
             && this.list.getValue( this.router.persondata, 'photo', true ) == ''
           )
           ? 'buttons.requestcard'
           : 'buttons.save';
  }
}
