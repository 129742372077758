import { Component, OnInit, OnDestroy }   from '@angular/core';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { PhotoService }       from '@omnipas/services/photo.service';
import { RouterService }      from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-photopermissions',
  templateUrl: './photopermissions.component.html'
})
export class PhotoPermissionsComponent implements OnInit, OnDestroy {
  initialized: boolean = false;

  constructor ( public environment: EnvironmentService
              , public list:        ListService
              , public loading:     LoadingService
              , public message:     MessageService
              , public pageheader:  PageHeaderService
              , public photo:       PhotoService
              , public router:      RouterService
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata || !this.environment.getEnvValue('changepublishpermission') ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.photopermissions', 'pagetext.photopermissions' );
    }

    this.photo.photoAgreed = this.photo.photo !== null;
    this.photo.publishPermission = this.list.getValue( this.router.persondata, 'publishPermission', true, true ) == 'True';

    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  async submit() {
    await this.photo.savepubperm();
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  showpopup() {
    this.router.showpermissionsinfo = true;
  }

  ngOnDestroy() {
    return;
  }
}
